<template>
  <FormDefault
    buttonText="Login"
    :buttonFullWidth="false"
    :centerButton="true"
    @submit="login"
    :disabled="captchaIsLoading"
    :submitting="submitting"
    buttonSize="lg"
  >
    <FormInputText
      label="Email Address"
      :marginBottom="5"
      placeholder="Email"
      autoComplete="email"
      :validation="validation.email"
      v-model="formState.email"
      type="email"
      name="email"
    />
    <FormInputText
      label="Password"
      :marginBottom="1"
      placeholder="Password"
      :validation="validation.password"
      v-model="formState.password"
      type="password"
      name="password"
    />
    <div class="d-flex justify-content-end mb-4">
      <NuxtLink class="link-primary" to="/forgot-password">Forgot password</NuxtLink>
    </div>
    <ReCaptcha ref="captchaRef" @event="handleCaptchaEvent" />
    <template v-slot:alert-message>
      <AlertMessage v-if="loginError" class="mt-5" type="error">
        {{ loginError }}
      </AlertMessage>
    </template>
  </FormDefault>
</template>

<script>
import { defineComponent, useContext, reactive, ref, useStore } from '@nuxtjs/composition-api';
import useVuelidate from '@vuelidate/core';
import useToast from '@/hooks/toast';
import useValidators from '@/hooks/validators';
import FormDefault from '@/components/form/Default';
import FormInputText from '@/components/form/input/Text';
import AlertMessage from '@/components/AlertMessage';
import ReCaptcha from '@/components/form/custom/ReCaptcha';
import useReCaptcha from '@/hooks/reCaptcha';
import useBraze from '@/hooks/braze';
import useMixpanel from '@/hooks/mixpanel';
import useAnalytics from '@/hooks/analyticsService';
import * as EVENT from '@/constants/analyticsEvent';

export default defineComponent({
  components: {
    FormDefault,
    FormInputText,
    AlertMessage,
    ReCaptcha,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const formState = reactive({
      email: '',
      password: '',
    });

    const toast = useToast();
    const store = useStore();
    const mixpanel = useMixpanel();

    const { captchaRef, handleCaptchaEvent, triggerCaptcha, onCaptchaSuccess } = useReCaptcha();

    const { school } = store.getters;

    const captchaIsLoading = ref(true);

    const submitting = ref(false);
    const loginError = ref(null);

    const { required, email, maxStringLength } = useValidators();

    const formRules = {
      email: { required, email, maxStringLength },
      password: { required, maxStringLength },
    };

    const validation = useVuelidate(formRules, formState, { $lazy: true });

    const { $auth } = useContext();

    const braze = useBraze();
    const analytics = useAnalytics();

    const login = async () => {
      loginError.value = null;
      const validated = await validation.value.$validate();
      if (!validated) {
        return;
      }

      triggerCaptcha();
    };

    onCaptchaSuccess(async (recaptchaToken) => {
      submitting.value = true;

      try {
        const success = await $auth.loginWith('careertoolsAuth', {
          email: formState.email,
          password: formState.password,
          schoolId: school.id,
          recaptchaToken,
        });

        if (!success) {
          loginError.value = 'You have entered an invalid username or password.';
          toast.error('Could not log you in.');
          formState.password = '';
        } else {
          braze.changeUser($auth.user.guid);

          if (props.isModal) {
            emit('close');
            toast.success('Successfully logged in!');
          }

          if ($auth.user.inUseAuthMethod.verified) {
            analytics.track(EVENT.CTX_USER_LOGGED_IN);
            mixpanel.login($auth.user.guid);
          }
        }
      } catch (e) {
        submitting.value = false;
        if (e?.message === 'no-password') {
          loginError.value =
            "There was an issue logging you in, it's required that you reset your password by clicking the forgot password link.";
          toast.error('Could not log you in.');
          return;
        }
        toast.error('An unexpected error occured.');
        throw e; // unexpected error
      }
      submitting.value = false;
    });

    return {
      formState,
      validation,
      login,
      submitting,
      loginError,
      captchaIsLoading,
      handleCaptchaEvent,
      captchaRef,
    };
  },
});
</script>

<style scoped lang="scss">
.link-primary {
  color: $business-1;
}
</style>
