<template>
  <form
    class="d-flex flex-column"
    :class="{ 'form-small': small }"
    @submit.prevent="submit"
    novalidate
  >
    <slot></slot>
    <div class="mt-4 d-flex align-items-center" :class="{ 'justify-content-center': centerButton }">
      <Button
        v-if="backButton"
        @click="$emit('back')"
        :size="buttonSize"
        :userType="userType"
        variant="underlined"
        class="me-5"
      >
        Back
      </Button>
      <Button
        v-if="nextButton"
        :disabled="nextDisabled"
        @click="emitNext"
        :size="buttonSize"
        :userType="userType"
        variant="filled"
        class="me-5"
      >
        Next
      </Button>
      <Button
        v-if="showButton"
        :fullWidth="buttonFullWidth"
        :loading="submitting"
        :size="buttonSize"
        variant="filled"
        :userType="userType"
        type="submit"
        >{{ buttonText }}
      </Button>
      <Button
        class="ms-3"
        v-if="includeCancelButton"
        @click="$emit('cancelled')"
        :to="cancelButtonRoute"
        :fullWidth="buttonFullWidth"
        :disabled="submitting"
        :size="buttonSize"
        variant="underlined"
        :userType="userType"
        type="button"
        >or cancel
      </Button>
    </div>
    <div>
      <slot name="alert-message"></slot>
    </div>
  </form>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    userType: {
      type: String,
      required: false,
      default: 'student',
    },
    variant: {
      type: String,
      required: false,
      default: '',
    },
    buttonFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
    centerButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonSize: {
      type: String,
      required: false,
      default: 'md',
    },
    cancelButtonRoute: {
      required: false,
      type: String,
    },
    includeCancelButton: {
      type: Boolean,
      required: false,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    nextButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(...[, { emit }]) {
    const emitNext = (event) => {
      event.preventDefault();
      emit('next');
    };
    const submit = () => {
      emit('submit');
    };
    return { submit, emitNext };
  },
});
</script>
