<template>
  <div
    class="max-width-container"
    :class="centered && 'max-width-container-centered'"
    :style="{ 'max-width': `${maxWidth}px` }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: Number,
      required: true,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.max-width-container {
  width: 100%;
}
.max-width-container-centered {
  margin-left: auto;
  margin-right: auto;
}
</style>
