<template>
  <Page pageTitle="Login">
    <SsoSignInRedirect v-if="school.sso_provider" :provider="school.sso_provider" />
    <ManualLogin v-else />
    <template v-if="!isFreeTierSchool" slot="right">
      <SchoolLogo />
    </template>
  </Page>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Page from '@/components/layout/Page';
import SchoolLogo from '@/components/layout/SchoolLogo';
import useSchool from '@/hooks/school';
import ManualLogin from '@/components/page/login/ManualLogin';
import SsoSignInRedirect from '@/components/sso/SsoSignInRedirect';
import { PRICING_TIERS_ALL } from '@/constants/pricingTiers';

export default defineComponent({
  auth: 'guest',
  components: {
    Page,
    SchoolLogo,
    ManualLogin,
    SsoSignInRedirect,
  },
  meta: {
    allowedPricingTiers: PRICING_TIERS_ALL,
  },
  setup() {
    const { school, isFreeTierSchool } = useSchool();
    return { school, isFreeTierSchool };
  },
});
</script>
