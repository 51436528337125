<template>
  <div>
    <div class="card loading">
      <div class="card-1 animate-pulse"></div>
      <div class="card-2 p-3">
        <div class="row">
          <div class="col-4">
            <div class="inner-card animate-pulse"></div>
          </div>
          <div class="col-8">
            <div class="inner-card animate-pulse"></div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <div class="inner-card animate-pulse"></div>
          </div>
          <div class="col-6">
            <div class="inner-card animate-pulse"></div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <div class="inner-card animate-pulse"></div>
          </div>
          <div class="col-10">
            <div class="inner-card animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 5px;
  overflow: hidden;

  .inner-card {
    height: 30px;
    background-color: #eee;
  }

  .card-1 {
    height: 200px;
    background-color: #eee;
  }

  .card-2 {
    height: 100%;
  }

  .h-screen {
    height: 100vh;
  }

  // Animate to give loading effect
  .animate-pulse {
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
}
</style>
