<template>
  <div class="input-container" :class="[`${inputStyle}-text`, `mb-${marginBottom}`]">
    <slot name="label"></slot>
    <template v-if="!noLabel && label">
      <InputLabel :tooltipContent="tooltipContent" :name="name" :label="label" />
    </template>

    <div class="d-flex input-box-panel" :class="{ 'is-invalid-panel': validation.$invalid }">
      <input
        @keydown.enter="$emit('enter', $event)"
        :readonly="readOnly"
        :value="value"
        @input="updateValue"
        :type="type"
        :name="name"
        :id="name"
        :step="step"
        :placeholder="placeholder"
        :autocomplete="autoComplete"
        class="form-control"
        :class="
          !validation.$pending && {
            'is-valid': !validation.$invalid && value && validation.$dirty,
            'is-invalid': validation.$invalid,
            'd-none': hideInput,
          }
        "
        @focus="focus = true"
        @blur="validationTouch"
        :disabled="disabled"
        :maxLength="maxLength ? maxLength : null"
        :aria-describedby="!!errorIDString && errorIDString"
        :aria-labelledby="noLabel && ariaLabelledBy"
        :aria-required="ariaRequired"
        :aria-label="ariaLabel"
        :min="minVal"
        :max="maxVal"
      />
      <slot name="right-component"></slot>
    </div>
    <span
      aria-live="assertive"
      v-if="maxLength && focus && typeof value !== 'undefined'"
      v-text="`${maxLength - value.length} characters remaining`"
      class="max-length"
      :class="{ max: value.length === maxLength }"
    />

    <ValidationOutput :validation="validation" :name="name" :label="label" />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import formInputValidationProp from '@/utils/formInputValidationProp';
import useFormInputAccessibility from '@/hooks/formInputAccessibility';
import InputLabel from '@/components/form/input/Label';
import ValidationOutput from '@/components/form/Validation';

export default defineComponent({
  components: { InputLabel, ValidationOutput },
  props: {
    inputStyle: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'school-website'].indexOf(v) !== -1,
    },
    width: {
      type: String,
      required: false,
    },
    validation: formInputValidationProp,
    value: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    ariaLabelledBy: {
      type: String,
      required: false,
    },
    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    autoComplete: {
      type: String,
      required: false,
      default: 'off',
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    marginBottom: {
      type: Number,
      required: false,
      default: 3,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    step: {
      type: Number,
      required: false,
    },
    ariaRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    minVal: {
      type: [Number, Boolean, String],
      required: false,
      default: false,
    },
    maxVal: {
      type: [Number, Boolean, String],
      required: false,
      default: false,
    },
    tooltipContent: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const focus = ref(false);
    const validationTriggered = ref(false);

    const validationTouch = () => {
      focus.value = false;
      if (!props.disabled.value) {
        props.validation.$touch();
        validationTriggered.value = true;
      }

      emit('blur');
    };

    const updateValue = (event) => {
      // reset so validation is not triggered on every key stroke
      if (validationTriggered.value) {
        props.validation.$reset();
      }
      emit('input', event.target.value);
    };

    const { errorIDs, errorIDString } = useFormInputAccessibility(props);

    return { updateValue, validationTouch, focus, errorIDs, errorIDString };
  },
});
</script>

<style scoped lang="scss">
.default-text {
  input {
    border: 1px solid $primary-1;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 14px;

    &:focus {
      border: 1px solid $primary-1;
      box-shadow: 0 0 0 1px $primary-1;
    }

    @include form-control-validation-state;
  }

  .input-container {
    position: relative;
  }

  .validation-spinner {
    position: absolute;
    top: 17px;
    right: 15px;
  }
}

.max-length {
  // float: right;
  font-size: toRem(11);

  &.max {
    color: $invalid-red;
  }
}

.school-website-text {
  input {
    padding: 0.7rem 0.7rem;
    background: #ffffff;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    border: none;
  }
}

.invalid-feedback {
  display: block;
}
.border-right-none{
  input{
    border-radius: 8px 0 0 8px !important; 
    border-right: none !important;
  }
}
</style>
