var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:( _obj = {
    'has-hover': _vm.hover,
    shadow: _vm.shadows,
    border: _vm.borders,
    selected: _vm.selected,
    'h-100': _vm.maxHeight
  }, _obj[("card-" + _vm.type)] = true, _obj[("px-" + _vm.paddingX)] = true, _obj[("py-" + _vm.paddingY)] = true, _obj ),attrs:{"role":!!_vm.title && 'group',"aria-label":_vm.title},on:{"click":_vm.click}},[(_vm.loading)?_c('CardLoading'):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }