<template>
  <div
    class="card"
    :class="{
      'has-hover': hover,
      shadow: shadows,
      border: borders,
      selected: selected,
      'h-100': maxHeight,
      [`card-${type}`]: true,
      [`px-${paddingX}`]: true,
      [`py-${paddingY}`]: true,
    }"
    @click="click"
    :role="!!title && 'group'"
    :aria-label="title"
  >
    <CardLoading v-if="loading" />
    <slot v-else></slot>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import CardLoading from '@/components/layout/card/Loading';

export default defineComponent({
  components: {
    CardLoading,
  },
  props: {
    shadows: {
      type: Boolean,
      required: false,
      default: false,
    },
    borders: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxHeight: {
      type: Boolean,
      default: false,
      required: false,
    },
    paddingX: {
      type: Number,
      default: 4,
    },
    paddingY: {
      type: Number,
      default: 4,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: (v) => ['default', 'gray', 'blue', 'cloud-blue'].indexOf(v) !== -1,
    },
    hover: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(...[, { emit }]) {
    const click = (e) => {
      emit('click', e);
    };

    return { click };
  },
});
</script>

<style scoped lang="scss">
.card {
  border-radius: 16px;
}

.card-gray {
  background: $card-bg-alt; /* $athens-gray; */
  border: 1px solid $card-border; /* #dadada; */

  &.has-hover {
    cursor: pointer;
  }

  &.has-hover:hover {
    border-color: #2462d2;
  }
}

.card-blue {
  background: $card-bg-alt-2; /* $athens-gray; */
  border: 1px solid $card-border; /* #dadada; */

  &.has-hover {
    cursor: pointer;
  }

  &.has-hover:hover {
    border-color: #2462d2;
  }
}

.card-cloud-blue {
  background: $cloud-blue;
  border: none;
}

.card-default {
  background: $card-bg; /* #fff; */
  border: 1px solid $card-border; /* #fff; */

  &.shadow {
    box-shadow: 0 0 40px #c3c9d2;
  }

  &.border {
    border: 1px solid $bg-grey;
  }

  &.has-hover {
    cursor: pointer;
  }

  &.has-hover:hover {
    border-color: #2462d2 !important;
  }

  &.selected {
    border: 3px solid #6260f7 !important;
  }
}
</style>
