import { ref, useContext, computed } from '@nuxtjs/composition-api';
import useEventCallback from '@/hooks/eventCallback';
import useSchool from '@/hooks/school';

export default function useReCaptcha() {
  const captchaRef = ref(null);

  const { $logger } = useContext();

  const { school } = useSchool();

  const enabled = computed(() => {
    if (!school.value) {
      return true;
    }

    return school.value.recaptcha_enabled;
  });

  const { register, trigger } = useEventCallback();

  const triggerCaptcha = () => {
    if (!enabled.value) {
      trigger('temp_recaptcha_token');
      return;
    }

    captchaRef.value.trigger();
  };

  const handleCaptchaEvent = (event) => {
    if (event.type === 'verify') {
      trigger(event.token);
      return;
    }

    if (event.type === 'error') {
      $logger.error(event.error);
    }
  };

  return {
    captchaRef,
    triggerCaptcha,
    handleCaptchaEvent,
    onCaptchaSuccess: register,
  };
}
