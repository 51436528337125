<template>
  <CenteredContainer>
    <MaxWidthContainer :maxWidth="450">
      <div class="d-flex flex-column align-items-center">
        <PlatformLogo v-if="isFreeTierSchool" class="mb-3" :width="100" variant="iconmark-dark" />
        <h3 class="mb-3 text-center">Login to your account</h3>
        <p class="subtitle text-center mb-5">Enter your details below to login:</p>
        <CardDefault class="w-100 px-5 px-sm-6 py-4 mb-4" :border="true" type="cloud-blue">
          <LoginForm />
        </CardDefault>
        <p v-if="school.website_public_registration" class="text-center">
          Don’t have an account?
          <NuxtLink class="text-royal-blue" to="/register">Register here</NuxtLink>
        </p>
      </div>
    </MaxWidthContainer>
  </CenteredContainer>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import CenteredContainer from '@/components/layout/CenteredContainer';
import MaxWidthContainer from '@/components/layout/MaxWidthContainer';
import CardDefault from '@/components/layout/card/Default';
import LoginForm from '@/components/page/login/Form';
import useSchool from '@/hooks/school';
import PlatformLogo from '@/components/icon/PlatformLogo';

export default defineComponent({
  components: {
    CenteredContainer,
    MaxWidthContainer,
    CardDefault,
    LoginForm,
    PlatformLogo,
  },
  props: {},
  setup() {
    const { school, isFreeTierSchool } = useSchool();

    return { school, isFreeTierSchool };
  },
});
</script>

<style scoped lang="scss">
h3 {
  font-weight: 700;
  font-size: 22px;
}
.subtitle {
  font-size: 14px;
  color: $primary-grey;
  font-weight: 400;
}
</style>
