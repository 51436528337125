import { computed } from '@nuxtjs/composition-api';
import useAuth from '@/hooks/auth';
import { useToast as usetoastDefault } from 'vue-toastification/composition';

export default function useToast() {
  const { user } = useAuth();

  const timeout = computed(() => {
    if (user.value) {
      return parseInt(user.value.settings.accessibility.toast_timeout_seconds, 10) * 1000;
    }

    return 6000;
  });

  const toast = usetoastDefault();

  const success = (msg) => {
    toast.success(msg, {
      timeout: timeout.value,
    });
  };

  const error = (msg) =>
    toast.error(msg, {
      timeout: timeout.value,
    });

  return {
    success,
    error,
  };
}
