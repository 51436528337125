import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable';

const GENERATE_OAUTH_REDIRECT_URL = gql`
  mutation generateOauthRedirectUrl($provider: String!, $type: String!) {
    generateOauthRedirectUrl(provider: $provider, type: $type) {
      error_code
      error_id
      url
    }
  }
`;

export default function sendDestinationSurveyNotification() {
  return useMutation(GENERATE_OAUTH_REDIRECT_URL);
}
