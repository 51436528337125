<template>
  <div class="logo-container justify-content-center">
    <img
      v-if="school.logo_full"
      :src="`${$config.publicObjectStorageUrl}/${school.logo_full}`"
      class="img-fluid"
      :alt="`${school.name} logo`"
    />
    <Heading v-else size="h2" styleSize="h4">{{ school.name }}</Heading>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import useSchool from '@/hooks/school';
import Heading from '@/components/Heading';

export default defineComponent({
  components: {
    Heading,
  },
  setup() {
    const { school } = useSchool();
    return { school };
  },
});
</script>

<style lang="scss" scoped>
.logo-container {
  border-radius: 16px;
  padding: 20px 25px;
  background-color: white;
  display: none;

  @include m-xxl {
    display: flex;
  }
}
</style>
