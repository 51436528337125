<template>
  <Popover>
    <template v-slot:trigger="{ contentId, isOpen }">
      <button
        :aria-describedby="contentId"
        :aria-expanded="isOpen"
        aria-haspopup="true"
        class="no-style"
        aria-label="help"
      >
        <img src="/icons/help-dark.svg" height="18" width="18" alt="" />
      </button>
    </template>
    <template v-slot:content>
      <div v-html="content"></div>
    </template>
  </Popover>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Popover from '@/components/floating/Popover.vue';

export default defineComponent({
  components: {
    Popover,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
