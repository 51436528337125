<template>
  <vue-hcaptcha
    ref="hcaptchaRef"
    size="invisible"
    :sitekey="$config.hcaptchaSitekey"
    @trigger="trigger"
    @verify="onVerify"
    @error="onError"
  ></vue-hcaptcha>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default defineComponent({
  components: { VueHcaptcha },
  setup(...[, { emit }]) {
    const hcaptchaRef = ref(null);

    const trigger = () => {
      const recaptchaElement = hcaptchaRef.value;
      recaptchaElement.execute();
    };

    const onVerify = (token) => {
      emit('event', {
        type: 'verify',
        token,
      });
    };

    const onError = (error) => {
      emit('event', {
        type: 'error',
        error,
      });
    };

    return { trigger, onVerify, onError, hcaptchaRef };
  },
});
</script>

<style scoped></style>
