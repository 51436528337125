<template>
  <div :class="`alert-message alert-message-${type}`" role="alert">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: 'success',
      validator: (v) => ['success', 'error', 'warning'].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.alert-message {
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 6px;

  &.alert-message-error {
    background: rgba(226, 15, 54, 0.05);
    color: $invalid-red;
  }

  &.alert-message-warning {
    background: rgba(244, 184, 58, 0.15);
    color: $primary-1;
  }

  &.alert-message-success {
    color: $valid-green;
  }
}
</style>
