<template>
  <CenteredContainer>
    <div class="d-flex flex-column align-items-center pt-10">
      <h1 class="mb-5">Signing you in</h1>
      <Loader :width="250" :height="250" />
    </div>
  </CenteredContainer>
</template>

<script>
import { defineComponent, onMounted, useRouter } from '@nuxtjs/composition-api';
import CenteredContainer from '@/components/layout/CenteredContainer';
import Loader from '@/components/loaders/Loader';
import useGenerateOauthRedirectUrl from '@/graphql/hooks/mutation/generateOauthRedirectUrl';

export default defineComponent({
  components: {
    CenteredContainer,
    Loader,
  },
  props: {
    provider: { type: String, required: true },
  },
  setup(props) {
    const { mutate: generateOauthRedirectUrl } = useGenerateOauthRedirectUrl();

    const router = useRouter();

    const triggerAuth = async () => {
      try {
        const res = await generateOauthRedirectUrl({
          provider: props.provider,
          type: 'auth',
        });

        if (res.data.generateOauthRedirectUrl.error_code) {
          router.push({
            name: 'sso-error',
            params: {
              provider: props.provider,
              errorCode: res.data.generateOauthRedirectUrl.error_code,
            },
            query: { error_id: res.data.generateOauthRedirectUrl.error_id },
          });
          return;
        }

        window.location = res.data.generateOauthRedirectUrl.url;
      } catch (e) {
        router.push({
          name: 'sso-error',
          params: { provider: props.provider, errorCode: 'unknown' },
        });
      }
    };

    onMounted(() => {
      triggerAuth();
    });

    return {};
  },
});
</script>

<style lang="scss" scoped></style>
